const routes = [
    {
        path: "/test",
        name: "test",
        component:() => import(/* webpackChunkName: "test" */ '../views/test/test.vue')
    },
    // {path: "/", redirect: "/test"},
    {path: "/", redirect: "mainPage"},
    {
        path: "/",
        name: "layout",
        component: () => import(/* webpackChunkName: "Home" */ '@/components/layout/index.vue'),
        children:[
            {
                path: "/mainPage",
                name: "mainPage",
                meta:{
                    activeNav:'mainPage'
                },
                component:() => import(/* webpackChunkName: "mainPage" */ '../views/mainPage/mainPage.vue')
            },
            {
                path: "/productCenter",
                name: "productCenter",
                redirect: "/productCenter/salesManagement",
                component:() => import(/* webpackChunkName: "productCenter" */ '../views/productCenter/productCenterLayout.vue'),
                children:[
                    {
                        path: "/productCenter/materielManagement",
                        name: "materielManagement",
                        meta:{
                            activeNav:'productCenter'
                        },
                        component:() => import(/* webpackChunkName: "materielManagement" */ '../views/productCenter/materielManagement.vue')
                    },
                    {
                        path: "/productCenter/trialManagement",
                        name: "trialManagement",
                        meta:{
                            activeNav:'productCenter'
                        },
                        component:() => import(/* webpackChunkName: "trialManagement" */ '../views/productCenter/trialManagement.vue')
                    },
                    {
                        path: "/productCenter/administrationManagement",
                        name: "administrationManagement",
                        meta:{
                            activeNav:'productCenter'
                        },
                        component:() => import(/* webpackChunkName: "administrationManagement" */ '../views/productCenter/administrationManagement.vue')
                    },
                    {
                        path: "/productCenter/salesManagement",
                        name: "salesManagement",
                        meta:{
                            activeNav:'productCenter'
                        },
                        component:() => import(/* webpackChunkName: "salesManagement" */ '../views/productCenter/salesManagement.vue')
                    },
                    {
                        path: "/productCenter/qualityControl",
                        name: "qualityControl",
                        meta:{
                            activeNav:'productCenter'
                        },
                        component:() => import(/* webpackChunkName: "qualityControl" */ '../views/productCenter/qualityControl.vue')
                    },
                    {
                        path: "/productCenter/financialManagement",
                        name: "financialManagement",
                        meta:{
                            activeNav:'productCenter'
                        },
                        component:() => import(/* webpackChunkName: "financialManagement" */ '../views/productCenter/financialManagement.vue')
                    },
                    {
                        path: "/productCenter/InstrumentDataBackup",
                        name: "InstrumentDataBackup",
                        meta:{
                            activeNav:'productCenter'
                        },
                        component:() => import(/* webpackChunkName: "InstrumentDataBackup" */ '../views/productCenter/InstrumentDataBackup.vue')
                    },
                    {
                        path: "/productCenter/customerServiceSystem",
                        name: "customerServiceSystem",
                        meta:{
                            activeNav:'productCenter'
                        },
                        component:() => import(/* webpackChunkName: "customerServiceSystem" */ '../views/productCenter/customerServiceSystem.vue')
                    },
                ]
            },
            {
                path: "/solution",
                name: "solution",
                meta:{
                    activeNav:'solution'
                },
                component:() => import(/* webpackChunkName: "solution" */ '../views/solution/solution.vue')
            },
            {
                path: "/aboutUs",
                name: "aboutUs",
                meta:{
                    activeNav:'aboutUs'
                },
                component:() => import(/* webpackChunkName: "aboutUs" */ '../views/aboutUs/aboutUs.vue')
            },
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        exclude: ['6cb88717bd4151158c8fc2dc19cc3d2b.txt','baidu_verify_codeva-m7Z3Qz4emO.html','sogousiteverification.txt','BingSiteAuth.xml','googled176fbf43e3e9b81.html'],
        redirect: "mainPage"
    },
]

export default routes
